import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/layout"

const Newsletter: React.FC = () => (
  <Layout>
    <Helmet title="Newsletter" />
    <div className="container">
      <div className="row mt-3">
        <div className="main-col my-3">
          <h1>Flèch'Infos Grand Est</h1>
          <h2>L'info flash du tir à l'arc</h2>
          <p>Retrouvez ici tous les numéros de votre lettre d'information.</p>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">N°</th>
                <th scope="col">Date</th>
                <th scope="col">Lien</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Flèch'Infos n°14</td>
                <td>Janvier 2024</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/qL37tLdTCYJiSgG" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°13</td>
                <td>Novembre 2023</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/94KKig2HK8QjSKp" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°12</td>
                <td>Mai 2023</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/iocifgoSDMbLtmq" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°11</td>
                <td>Mars 2023</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/o9Ax3nHyEAZfaiQ" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°10</td>
                <td>Janvier 2023</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/QnBdmZw3B8y8J57" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°9</td>
                <td>Décembre 2022</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/LekeoAdFeAAtH8R" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°8</td>
                <td>Novembre 2022</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/QoKxPFwJ2qtbJoi" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°7</td>
                <td>Octobre 2022</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/BxEkKnffbiiFmyA" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°6</td>
                <td>Septembre 2022</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/nWrMoiMgDL2QELm" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°5</td>
                <td>Août 2022</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/7aAxxMWqwxwPTo2" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°4</td>
                <td>Juillet 2022</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/RksJXM2T8q3JJaX" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°3</td>
                <td>Juin 2022</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/GZdMkx8azX55NCq" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°2</td>
                <td>Mai 2022</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/NMPwaPcFEWf68eG" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Flèch'Infos n°1</td>
                <td>Avril 2022</td>
                <td>
                  <a href="https://cloud.tiralarc-grand-est.fr/s/7mFzcxrZsebZBet" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
)

export default Newsletter
